import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import ReCAPTCHA from 'react-google-recaptcha';
import './ScheduleShowing.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const ScheduleShowing = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const ListingUnitId = queryParams.get('uid');
    console.log('ListingUnitId : ' + ListingUnitId);
    const [captcha, setCaptcha] = useState(false);
    const navigate = useNavigate();

    const [propertyData, setPropertyData] = useState([]);
    const [unitData, setUnitData] = useState({});
    const [scheduleData, setScheduleData] = useState({
        "property": '',
        "unitID": '',
        "name": '',
        "email": '',
        "phone": '',
        "option1": "",
        "option2": "",
        "option3": ""
    });

    const handleCaptcha = (val) => {
        val ? setCaptcha(true) : setCaptcha(false);
    };

    const formatDateTime = (inputDate) => {
        return new Date(inputDate).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
            timeZone: 'America/Toronto',
        });
    };

    const isDateValid = (inputDate) => {
        return new Date(inputDate) > new Date();
    };

    const validateFields = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!scheduleData.property) {
            toast.error("Property is required.");
            return false;
        }

        if (!scheduleData.name.trim()) {
            toast.error("Name cannot be empty.");
            return false;
        }

        if (!emailRegex.test(scheduleData.email)) {
            toast.error("Please enter a valid email address.");
            return false;
        }

        if (scheduleData.phone.length < 10) {
            toast.error("Phone number must be at least 10 digits.");
            return false;
        }

        if (!scheduleData.option1) {
            toast.error("Option #1 date is required.");
            return false;
        }

        if (!isDateValid(scheduleData.option1)) {
            toast.error("Option #1 date must not be in the past.");
            return false;
        }

        if (scheduleData.option2 && !isDateValid(scheduleData.option2)) {
            toast.error("Option #2 date must not be in the past.");
            return false;
        }

        if (scheduleData.option3 && !isDateValid(scheduleData.option3)) {
            toast.error("Option #3 date must not be in the past.");
            return false;
        }

        return true;
    };

    const fetchPropertyData = () => {
        const apiUrl = process.env.REACT_APP_API_URL + '/v1/listings/kwpweb/listing';

        fetch(apiUrl, {
            method: 'GET'
        }).then(response => response.json())
            .then(responseData => {
                setPropertyData(responseData);
            })
            .catch(error => {
                console.error('Error fetching property data:', error);
                toast.error('Error fetching property data. Please try again later.');
            });
    };

    const fetchUnitData = () => {
        const apiUrl = process.env.REACT_APP_API_URL + `/v1/listings/kwpweb/unit/${ListingUnitId}`;

        fetch(apiUrl, {
            method: 'GET'
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                navigate('/');
            }
        }).then(responseData => {
            setUnitData(responseData);
            setScheduleData({
                "name": '',
                "email": '',
                "phone": '',
                "option1": "",
                "option2": "",
                "option3": "",
                "property": responseData.Property?.Name,
                "unitID": responseData.Unit?.Id,
            });
        }).catch(error => {
            console.error('Error fetching unit data:', error);
            toast.error('Error fetching unit data. Please try again later.');
        });
    };

    const onSubmit = () => {
        if (!validateFields()) {
            return;
        }

        const apiUrl = process.env.REACT_APP_API_URL + '/v1/mailsend/propertyBookingRequest';

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        scheduleData.option1 = formatDateTime(scheduleData.option1);
        scheduleData.option2 = scheduleData.option2 ? formatDateTime(scheduleData.option2) : "";
        scheduleData.option3 = scheduleData.option3 ? formatDateTime(scheduleData.option3) : "";

        const raw = JSON.stringify(scheduleData);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${apiUrl}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 200) {
                    toast.success('Your request has been submitted successfully.');
                    setScheduleData({
                        "property": '',
                        "unitID": '',
                        "name": '',
                        "email": '',
                        "phone": '',
                        "option1": "",
                        "option2": "",
                        "option3": ""
                    });
                } else {
                    toast.warn(result.res);
                }
            })
            .catch(error => {
                console.log('error', error);
                toast.error('Oops! Something went wrong. Please try again later.');
            });
    };

    useEffect(() => {
        if (!ListingUnitId) {
            fetchPropertyData();
        } else {
            fetchUnitData();
        }
    }, [navigate]);

    const handlePropertyChange = (selectedOption) => {
        setScheduleData((prev) => ({
            ...prev,
            property: selectedOption.value
        }));
    };

    const propertyOptions = propertyData.map((data) => ({
        value: `${data.Property.Name}/${data.Unit.Id}`,
        label: (
            <div>
                <strong>{data.Property.Name}</strong>
                <br />
                <span style={{ fontSize: 'smaller', color: '#888' }}>{data.Property.PropertyUnitTypeTitle}</span>
            </div>
        )
    }));

    useEffect(() => {
        console.log(scheduleData);
        setScheduleData((prev) => ({
            ...prev,
            ['unitID']: scheduleData.property?.substring(Number(scheduleData.property.indexOf('/')) + 1, scheduleData.property.length)
        }));
    }, [scheduleData.property]);

    useEffect(() => {
        console.log(scheduleData);
    }, [scheduleData]);

    const handlePhoneChange = (phone) => {
        const sanitizedPhone = phone.replace(/[^0-9]/g, '');
        setScheduleData((prev) => ({
            ...prev,
            phone: sanitizedPhone
        }));
    };

    return (
        <>
            <KWNavbar></KWNavbar>
            <ToastContainer />
            <div className="container mt-5 pt-5">
                <div className="mt-5">
                    <div className="ScheduleShowing-header">WANT TO SEE THIS PROPERTY?</div>
                    <div className="ScheduleShowing-top-para">
                        Please fill out and submit the form below with your information and your preferred dates/times for a showing. We will do our best to accommodate your schedule!
                    </div>
                </div>
                <div className="ScheduleShowing-form">
                    <div className="ScheduleShowing-input">
                        <label htmlFor="Property">Property</label>
                        {
                            ListingUnitId ?
                                <div className='confirm-property-RgridT'><strong>{unitData.Property?.Name}</strong></div>
                                :
                                (
                                    <Select
                                        options={propertyOptions}
                                        onChange={handlePropertyChange}
                                        placeholder="Select Property"
                                    />
                                )
                        }
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="YourName">Your Name*</label>
                        <input type="text"
                            value={scheduleData.name}
                            onChange={(e) => setScheduleData((prev) => ({ ...prev, name: e.target.value }))}
                            className='required-number required-number2 w-100'
                            name="name"
                            id="YourName" />
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="Phone">Phone*</label>
                        <PhoneInput
                            country={'ca'}
                            value={scheduleData.phone}
                            onChange={handlePhoneChange}
                            placeholder="+1 (999) (999) (9999)"
                            inputProps={{
                                name: 'phone',
                                required: true,
                                autoFocus: true,
                            }}
                            inputStyle={{
                                width: '100%',
                            }}
                        />
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="EmailAddress">Email Address*</label>
                        <input type="text"
                            value={scheduleData.email}
                            onChange={(e) => setScheduleData((prev) => ({ ...prev, email: e.target.value }))}
                            className='required-number required-number2 w-100'
                            name="email"
                            id="EmailAddress" />
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="EmailAddress" style={{ opacity: "0" }}>Email Address*</label>
                        <div className="ScheduleShowing-Pleaseprovide">
                            <b>Date and Time of Availability:</b> <br />
                            <div>
                                (Please provide a couple of options if possible and we will do our best to accommodate you)
                            </div>
                        </div>
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="EmailAddress">Option #1</label>
                        <div>
                            <input value={scheduleData.option1} onChange={(e) => setScheduleData((prev) => ({ ...prev, option1: e.target.value }))} className='form-control' type="datetime-local" name="option1" id="" />
                        </div>
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="EmailAddress">Option #2</label>
                        <div>
                            <input value={scheduleData.option2} onChange={(e) => setScheduleData((prev) => ({ ...prev, option2: e.target.value }))} className='form-control' type="datetime-local" name="option2" id="" />
                        </div>
                    </div>
                    <div className="ScheduleShowing-input">
                        <label htmlFor="EmailAddress">Option #3</label>
                        <div>
                            <input value={scheduleData.option3} onChange={(e) => setScheduleData((prev) => ({ ...prev, option3: e.target.value }))} className='form-control' type="datetime-local" name="option3" id="" />
                        </div>
                    </div>

                    <div className='ScheduleShowing-input'>
                        <label htmlFor="EmailAddress" style={{ opacity: "0" }}>Email Address*</label>
                        <div>
                            <div className='mt-3 mb-1 rechaptcha-text'>To protect this website from spam, please check the box below.</div>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                onChange={handleCaptcha}
                            />
                            <div className="mt-4">
                                <button
                                    onClick={() => window.history.back()}
                                    type='button' className='btn btn-secondary CPgridButton1'>Back</button>
                                {
                                    captcha ?
                                        <button
                                            onClick={onSubmit}
                                            type='button'
                                            className='btn btn-primary ms-1 CPgridButton2'>Submit</button>
                                        : <button
                                            onClick={() => toast.warn("Please check the captcha")}
                                            type='button'
                                            className='btn btn-primary ms-1 CPgridButton2'>Submit</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <KWFooter></KWFooter>
        </>
    );
};
