import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import './N9.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { LineWave } from 'react-loader-spinner';
import ImpInfo from './ImpInfo';

export const N9 = () => {
    const [To, setTo] = useState('');
    const [From, setFrom] = useState('');
    const [AddressOf, setAddressOf] = useState('');
    const [TerminationDate, setTerminationDate] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');
    const [PhoneNumber, setPhoneNumber] = useState('');
    const [TerminationSignature, setTerminationSignature] = useState('');
    const [loading, setLoading] = useState(false);
    const [tenantChecked, setTenantChecked] = useState(true);
    const [repChecked, setRepChecked] = useState(false);
    const [ImpInfoVisible, setImpInfoVisible] = useState(false);

    const sanitizeInput = (input) => {
        return input.replace(/[^a-zA-Z0-9 .,@'-]/g, '');
    };

    const handleCaptcha = (val) => {
        console.log(val);
    };

    const handleTenantChecked = (event) => {
        const { checked } = event.target;
        setTenantChecked(checked);
        setRepChecked(!checked);
    };

    const handleRepChecked = (event) => {
        const { checked } = event.target;
        setRepChecked(checked);
        setTenantChecked(!checked);
    };

    const resetState = () => {
        setTo('');
        setFrom('');
        setAddressOf('');
        setTerminationDate('');
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setTerminationSignature('');
        setTenantChecked(true);
        setRepChecked(false);
    };

    const [TenentTermination, setTenentTermination] = useState({
        recipientName: FirstName,
        LastName: LastName,
        ToLandLordsName: To,
        FromTenantName: From,
        AddressOf: AddressOf,
        TerminationDate: TerminationDate,
        PhoneNumber: PhoneNumber,
        Signature: TerminationSignature,
        SignatureDate: new Date().toLocaleString(),
        TenantCheck: tenantChecked,
        RepresentativeCheck: repChecked,
    });

    useEffect(() => {
        setTenentTermination({
            tenantName: sanitizeInput(From),
            landlordName: sanitizeInput(To),
            terminationDate: TerminationDate,
            unitAddress: sanitizeInput(AddressOf),
            signatureOf: tenantChecked ? '1' : '2',
            firstName: sanitizeInput(FirstName),
            lastName: sanitizeInput(LastName),
            signature: sanitizeInput(TerminationSignature),
            phoneNumber: PhoneNumber,
        });
    }, [FirstName, TerminationDate, PhoneNumber, TerminationSignature, LastName, tenantChecked]);

    const SubmitTenentTermination = () => {
        if (To === '') {
            toast.warning("Landlords Name is required!");
        } else if (From === '') {
            toast.warning("Tenants Name is required!");
        } else if (AddressOf === '') {
            toast.warning("Address of the Rental Unit is required!");
        } else if (TerminationDate === '') {
            toast.warning("Termination Date is required!");
        } else if (new Date(TerminationDate) < Date.now()) {
            toast.warning("Termination Date could not be set to past date!");
        } else if (tenantChecked === false && repChecked === false) {
            toast.warning("Signed By is required!");
        } else if (FirstName === '') {
            toast.warning("First Name is required!");
        } else if (LastName === '') {
            toast.warning("Last Name is required!");
        } else if (PhoneNumber === '') {
            toast.warning("Phone Number is required");
        } else if (PhoneNumber.length < 10) {
            toast.warning("Phone Number must be of 10 digits");
        } else if (PhoneNumber.length > 10) {
            toast.warning("Phone Number should not exceed more than 10 digits");
        } else if (TerminationSignature === '') {
            toast.warning("Signature is required!");
        } else {
            console.log('TenentTermination');
            console.log(TenentTermination);
            setLoading(true);
            axios.post(`${process.env.REACT_APP_API_URL}/v1/formcontroller/n9`, TenentTermination).then((res) => {
                if (res.status === 200) {
                    toast.success('Termination request submitted successfully');
                    resetState();
                } else {
                    toast.error('Failed to submit termination request');
                }
                setLoading(false);
            });
        }
    };

    return (
        <>
            <KWNavbar />
            <ToastContainer />
            <div>
                {loading !== true ? (
                    <div className="container mt-5 pt-5">
                        <div className="mt-5">
                            <div className="TenentTenancy-header mb-4">Tenant's Notice to End the Tenancy (N9)</div>
                            <div className="TenentTenancy-Box px-3 pt-2 pb-4">
                                <div className="row">
                                    <div className="col-sm-12 col-lg-6">
                                        <label htmlFor="To" className='fw-bold mb-1'>To</label>
                                        <input className='required-number required-number2 form-control'
                                            value={To}
                                            onChange={(e) => setTo(sanitizeInput(e.target.value))}
                                            type="text" name="To" id="To"
                                            placeholder='Landlords Name' />
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                        <label htmlFor="From" className='fw-bold mb-1'>From</label>
                                        <input className='required-number required-number2 form-control'
                                            value={From}
                                            onChange={(e) => setFrom(sanitizeInput(e.target.value))}
                                            type="text" name="From"
                                            id="From" placeholder='Tenants Name' />
                                        <div style={{ opacity: "0.4" }}>Include all tenant names</div>
                                    </div>
                                </div>
                                <div className="pt-2">
                                    <label htmlFor="AddressOf" className='fw-bold mb-1'>Address of the Rental Unit :</label>
                                    <input className='required-number required-number2 form-control'
                                        value={AddressOf}
                                        onChange={(e) => setAddressOf(sanitizeInput(e.target.value))}
                                        type="text" name="AddressOf" id="AddressOf"
                                        placeholder='Unit Address' />
                                </div>
                                <div className="pt-2">
                                    <label htmlFor="AddressOf" className='fw-bold mb-1'>Termination date</label>
                                    <input className='required-number required-number2 form-control'
                                        value={TerminationDate}
                                        onChange={(e) => setTerminationDate(e.target.value)}
                                        type="date" name="TerminationDate" id="TerminationDate"
                                        placeholder='Termination date' />
                                </div>
                            </div>

                            <div className="pt-4">
                                <div className="TenentTenancy-Box px-3 pt-2 pb-2 mt-2">
                                    <ImpInfo
                                        visible={ImpInfoVisible}
                                        toggle={() => setImpInfoVisible(!ImpInfoVisible)}
                                    />
                                </div>
                                <div className="TenentTenancy-Box px-4 pt-2 pb-4 mt-5">
                                    <div>
                                        <b className="text-dark">Signed by</b>
                                        <div className="mt-2 d-flex">
                                            <div className="d-flex me-3">
                                                <input onChange={(e) => handleTenantChecked(e)} type="radio" id="optradio1" name="optradio" value="option1" checked={tenantChecked} />
                                                <label htmlFor="optradio1" className='mx-1'>Tenant</label>
                                            </div>
                                            <div className="d-flex ms-2">
                                                <input onChange={(e) => handleRepChecked(e)} type="radio" id="optradio2" name="optradio" value="option2" checked={repChecked} />
                                                <label htmlFor="optradio2" className='mx-1'>Representative</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-sm-12 col-lg-6 mb-md-3 mb-lg-0">
                                            <label htmlFor="FirstName"><b>First name</b></label>
                                            <input className='required-number required-number2 form-control'
                                                value={FirstName}
                                                onChange={(e) => setFirstName(sanitizeInput(e.target.value))}
                                                type="text" name="FirstName" id="FirstName"
                                                placeholder='First Name' />
                                        </div>
                                        <div className="col-sm-12 col-lg-6">
                                            <label htmlFor="LastName"><b>Last name</b></label>
                                            <input className='required-number required-number2 form-control'
                                                value={LastName}
                                                onChange={(e) => setLastName(sanitizeInput(e.target.value))}
                                                type="text" name="LastName" id="LastName"
                                                placeholder='Last Name' />
                                        </div>
                                    </div>
                                    <div className="pt-3">
                                        <label htmlFor="numberInput"><b>Phone Number</b></label>
                                        <div className="input-group">
                                            <span className="input-group-text input-group-text2"><img className='TenentTenancy-input-group-img' src="https://webstockreview.net/images/phone-icon-png-3.png" alt="" /></span>
                                            <input className="required-number required-number2 form-control"
                                                minLength={10}
                                                maxLength={10}
                                                value={PhoneNumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                                type="number" id="PhoneNumber" step="1" />
                                        </div>
                                    </div>
                                    <div className="pt-3">
                                        <label htmlFor="TerminationSignature"><b>Signature</b></label>
                                        <input className='required-number required-number2 form-control'
                                            value={TerminationSignature}
                                            onChange={(e) => setTerminationSignature(sanitizeInput(e.target.value))}
                                            type="text" name="TerminationSignature" id="TerminationSignature"
                                            placeholder='Signature' />
                                    </div>
                                </div>
                                <div className="reChaptcha-div mt-4">
                                    <div className='mb-3'>To protect this website from spam, please check the box below.</div>
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                        onChange={handleCaptcha}
                                    />
                                    <div className="mt-3">
                                        <button type="button" className='btn btn-primary' onClick={SubmitTenentTermination}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='lineWave_main'>
                        <div className="lineWave">
                            <LineWave
                                height="200px"
                                width="300px"
                                color="#0586f0"
                                ariaLabel="line-wave"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                firstLineColor=""
                                middleLineColor=""
                                lastLineColor=""
                            />
                        </div>
                    </div>
                )}
            </div>
            <KWFooter />
        </>
    );
};
