import React, { useState } from 'react';
import './Ebook.css';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const Ebook = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleValidation = () => {
    const { name, email, phone, message } = formData;
    let isValid = true;

    if (!isCaptchaVerified) {
      toast.error('Please complete the CAPTCHA.');
      isValid = false;
    }
    if (!name) {
      toast.error('Name is required.');
      isValid = false;
    }
    if (!phone) {
      toast.error('Phone number is required.');
      isValid = false;
    }
    if (!email) {
      toast.error('Email is required.');
      isValid = false;
    } else if (!validateEmail(email)) {
      toast.error('Invalid email address.');
      isValid = false;
    }
    if (!message) {
      toast.error('Message is required.');
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async () => {
    if (!handleValidation()) return;

    const sanitizedData = Object.keys(formData).reduce((acc, key) => {
      acc[key] = DOMPurify.sanitize(formData[key]);
      return acc;
    }, {});

    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/mailsend/contactus`,
        sanitizedData
      );

      if (response.data.status === 200) {
        toast.success('Form submitted successfully! Redirecting...');
        setTimeout(() => {
          navigate('/thankyou');
        }, 2000);
      } else {
        toast.error(response.data.res || 'Failed to submit the form. Please try again.');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.res || 'An unexpected error occurred.';
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <KWNavbar />
      <ToastContainer />
      <Container className="mt-5 pt-5 content-container">
        <Row>
          <Col sm={12} lg={6} className="content-section">
            <h1 className='textAlign'>Effortless Property Management for <span className="blue">New Investors</span></h1>
            <p><b>Discover Effortless Property Management with KW Property Management.</b></p>
            <p>Attention New and Novice Investors! Have you recently stepped into the world of real estate investment, or perhaps added another property to your budding portfolio?</p>
            <p>Before you dive in, equip yourself with the essential knowledge you need in the <b>Property Investor’s Checklist: How to Choose the Perfect Property Management Team.</b></p>
            <ul>
              <li><b>Know What It Takes to Succeed:</b> Discover the 7 essential services your property management company must offer, complete with handy checklists for instant clarity!</li>
              <li><b>Maximize Your Property Investments:</b> Get the inside scoop on the real pros and cons of hiring a property manager, whether you own one or multiple investment properties.</li>
              <li><b>Take Control of Your Investment Future:</b> Be prepared with powerful questions that empower you to interview property management companies with confidence, even if you're a total newcomer to property investment.</li>
            </ul>
            <p><a href="https://www.kwproperty.com/Download/property_investors_checklist.pdf" target="_blank" rel="noreferrer">Get Your FREE Copy of the Property Investor’s Checklist Now!</a></p>
          </Col>

          <Col sm={12} lg={6}>
            <Card className="p-3 form-card">
              <Form>
                <h4>DOWNLOAD THE GUIDE FREE</h4>
                <p>Fill in the form below and learn the 7 things to look for in the perfect property management company.</p>

                <Form.Group className="mb-3">
                  <Form.Control
                    value={formData.name}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    placeholder="Enter name"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={formData.phone}
                    name="phone"
                    onChange={handleChange}
                    type="text"
                    placeholder="Enter your phone number"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                    type="email"
                    placeholder="Enter your email"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={formData.message}
                    name="message"
                    onChange={handleChange}
                    as="textarea"
                    placeholder="Enter your message"
                    style={{ height: '100px' }}
                  />
                </Form.Group>

                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={() => setIsCaptchaVerified(true)}
                  onExpired={() => setIsCaptchaVerified(false)}
                />

                <Button
                  onClick={handleSubmit}
                  className="btn btn-primary mt-3"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : 'GET ACCESS'}
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h4>Why Choose KW Property Management?</h4>
            <p>
              At KW Property Management, we understand the challenges and uncertainties faced by new investors.
              Navigating the complexities of property management, handling tenant relations, and understanding legal nuances can be overwhelming.
            </p>
            <ol>
              <li><b>Timely Rent Collection:</b> Ensure consistent cash flow with our seamless rent collection process.</li>
              <li><b>Complete Leasing Solutions:</b> From digital advertising to tenant screening, we handle it all.</li>
              <li><b>Expertise in Financial Challenges:</b> Get expert recommendations when rent collection becomes tricky.</li>
              <li><b>Peace of Mind with Accounts Payable:</b> Let us manage your property-related bills efficiently.</li>
              <li><b>Emergency Support & Conflict Resolution:</b> Smooth tenant relations with mediation and conflict resolution.</li>
              <li><b>Legal Guidance:</b> Receive support for legal notices, eviction, and more.</li>
            </ol>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <h4>Frequently Asked Questions (FAQs):</h4>
            <ul>
              <li><b>Q:</b> I'm new to property investment; how can KW Property Management help me?
                <ul>
                  <li><b>A:</b> We offer comprehensive property management services tailored for novice investors. Download our free guide to learn more!</li>
                </ul>
              </li>
              <li><b>Q:</b> How do you handle tenant conflicts?
                <ul>
                  <li><b>A:</b> Our team specializes in mediation and emergency support for tenant concerns.</li>
                </ul>
              </li>
              <li><b>Q:</b> Are there any hidden fees in your services?
                <ul>
                  <li><b>A:</b> Transparency is key. All fees are outlined in our packages, with no hidden charges.</li>
                </ul>
              </li>
            </ul>
          </Col>
        </Row>

      </Container>
      <KWFooter />
    </>
  );
};
