import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form, Button, Container, InputGroup, Col, Card, Spinner } from 'react-bootstrap';
import { FaUser, FaLock } from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DOMPurify from 'dompurify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import ReCAPTCHA from 'react-google-recaptcha';

function LoginForm() {
  const [formData, setFormData] = useState({ username: '', password: '', rememberMe: false });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [captchaVerified, setCaptchaVerified] = useState(false);

  useEffect(() => {
    const isLogin = sessionStorage.getItem('isLogin');
    if (isLogin === 'true') navigate('/admin/dashboard');
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const sanitizedValue = type === 'checkbox' ? checked : DOMPurify.sanitize(value);
    setFormData((prev) => ({
      ...prev,
      [name]: sanitizedValue,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Ensure inputs are not empty
    if (!formData.username || !formData.password) {
      toast.error('Please fill in all required fields.');
      setIsLoading(false);
      return;
    }
    if(!captchaVerified){
      toast.error('Please checked the captcha.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/admin/login`, {
        user: formData.username.trim(),
        password: formData.password.trim(),
      });
      sessionStorage.setItem('isLogin', 'true');
      toast.success('Login successful!');
      navigate('/admin/dashboard');
    } catch (err) {
      toast.error(err.response?.data?.message || 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCaptcha = (value) => {
    setCaptchaVerified(!!value);
  };

  return (
    <Container className="pt-5">
      <ToastContainer />
      <div className="form-content">
        <Col className="mx-auto" sm={12} lg={5}>
          <Card className="pt-3 border-1 p-4 form-card mt-4">
            <h2 className="text-center mb-4">Login</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicUser">
                <Form.Label>Username</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FaUser />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    name="username"
                    value={formData.username}
                    onChange={handleInputChange}
                    required
                    disabled={isLoading}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formBasicPassword" className="mt-3">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FaLock />
                  </InputGroup.Text>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                    disabled={isLoading}
                  />
                </InputGroup>
              </Form.Group>

              {/* <Form.Group controlId="formBasicCheckbox" className="mt-3">
                <Form.Check
                  type="checkbox"
                  label="Remember me"
                  name="rememberMe"
                  checked={formData.rememberMe}
                  onChange={handleInputChange}
                  disabled={isLoading}
                />
              </Form.Group> */}
              <Form.Group>
                <div className="service-form-label2 mb-2">To protect this website from spam, please check the box below.</div>
                <div>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    onChange={handleCaptcha}
                  />
                </div>
              </Form.Group>

              <Button variant="primary" type="submit" className="mt-3 w-100" disabled={isLoading}>
                {!isLoading ? "Login" : <Spinner size="sm" animation="border" variant="light" />}
              </Button>
            </Form>
          </Card>
        </Col>
      </div>
    </Container>
  );
}

export default LoginForm;
