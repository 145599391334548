import React, { useState } from 'react';
import './ConfirmProperty.css';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify';
import Moment from 'react-moment';
import 'moment-timezone';
Moment.globalTimezone = 'America/Toronto';

export const ConfirmProperty = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: ''
  });
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const location = useLocation();
  const bookData = location.state?.bookData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleValidation = () => {
    const { name, email, mobile } = formData;
    let isValid = true;

    if (!captchaVerified) {
      toast.error('Please complete the CAPTCHA.');
      isValid = false;
    }
    if (!name.trim()) {
      toast.error('Name is required.');
      isValid = false;
    }
    if (!mobile.trim()) {
      toast.error('Mobile number is required.');
      isValid = false;
    }
    if (!email.trim()) {
      toast.error('Email is required.');
      isValid = false;
    } else if (!validateEmail(email)) {
      toast.error('Invalid email address.');
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async () => {
    if (!handleValidation()) return;

    const sanitizedData = {
      ...Object.keys(formData).reduce((acc, key) => {
        acc[key] = DOMPurify.sanitize(formData[key]);
        return acc;
      }, {}),
      eventId: bookData.eventId,
      templateData: {
        message: "To cancel the booking please call to 0000000000 or you can also cancel the booking by clicking ",
        link1: "<link>"
      }
    };

    setIsSubmitting(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/calender/book`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(sanitizedData)
        }
      );

      const result = await response.json();

      if (result.status === 200) {
        toast.success('Booking confirmed successfully!');
        setFormData({ name: '', email: '', mobile: '' });
      } else {
        toast.error(result.res || 'Failed to confirm booking. Please try again.');
      }
    } catch (error) {
      toast.error('An unexpected error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <KWNavbar />
      <ToastContainer />
      <div className="confirm-property-main mt-5 pt-5">
        <div className="confirm-property-head mt-5">Want to see this property?</div>
        <div className="confirm-property-para">
          To confirm your attendance to view <strong>{bookData?.summery}</strong> please fill out the form below and click 'Confirm'.
          A KWP Management Corp representative will be at the property for 15 minutes at that time listed below. Don't be late!
        </div>
        <div className="confirm-property-griddiv">
          <div className="confirm-property-grid">
            <div className="confirm-property-LgridT">Property</div>
            <div className="confirm-property-RgridT"><strong>{bookData?.summery}</strong></div>
          </div>
          <div className="confirm-property-grid">
            <div className="confirm-property-LgridT">Showing</div>
            <div className="confirm-property-RgridT">
              <strong>{bookData?.title} @
                <Moment date={bookData?.time} format="hh:mm a" />
              </strong>
            </div>
          </div>
          <div className="confirm-property-grid">
            <div className="confirm-property-LgridT">Your Name*</div>
            <div className="confirm-property-RgridT">
              <input
                value={formData.name}
                onChange={handleChange}
                type="text"
                name="name"
                className="form-control"
              />
            </div>
          </div>
          <div className="confirm-property-grid">
            <div className="confirm-property-LgridT">Phone*</div>
            <div className="confirm-property-RgridT">
              <input
                value={formData.mobile}
                onChange={handleChange}
                type="text"
                name="mobile"
                className="form-control"
              />
            </div>
          </div>
          <div className="confirm-property-grid">
            <div className="confirm-property-LgridT">Email Address*</div>
            <div className="confirm-property-RgridT">
              <input
                value={formData.email}
                onChange={handleChange}
                type="email"
                name="email"
                className="form-control"
              />
            </div>
          </div>
          <div className="ScheduleShowing-buttons">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={() => setCaptchaVerified(true)}
              onExpired={() => setCaptchaVerified(false)}
            />
            <div className="button-container">
              <button
                onClick={() => window.history.back()}
                type="button"
                className="btn btn-secondary CPgridButton1"
              >Back</button>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-primary ms-1 CPgridButton2 "
                disabled={!captchaVerified || isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Confirm'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <KWFooter />
    </>
  );
};
