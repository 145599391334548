import React, { useState, useEffect } from 'react';
import './contactus.css';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import KWNavbar from '../../components/navbar/navbar';
import KWFooter from '../../components/footer/footer';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import DOMPurify from 'dompurify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Contactus = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCaptcha = (val) => {
    setIsCaptchaVerified(Boolean(val));
  };

  function validateEmail(email) {
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleSubmit = async () => {
    const sanitizedContactData = {
      name: DOMPurify.sanitize(name),
      email: DOMPurify.sanitize(email),
      phone: DOMPurify.sanitize(phone),
      message: DOMPurify.sanitize(message),
    };

    if (!name) {
      toast.warning('Name is required!');
    } else if (!email) {
      toast.warning('Email is required!');
    } else if (!validateEmail(sanitizedContactData.email)) {
      toast.warning('Invalid Email!');
      return;
    } else if (!phone) {
      toast.warning('Phone number is required!');
    } else if (!message) {
      toast.warning('Your message is required!');
    } else {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/v1/mailsend/contactus`, sanitizedContactData);
        if (res.data.status === 200) {
          toast.success('Thanks for contacting us. We will get back to you shortly.');
          setName('');
          setEmail('');
          setPhone('');
          setMessage('');
        }
      } catch (error) {
        console.error('There was an error sending the contact form!', error);
        toast.error('There was an error sending your message. Please try again later.');
      }
    }
  };

  return (
    <>
      <KWNavbar />
      <ToastContainer />
      <Container className='mt-5 pt-5'>
        <Row>
          <Col xs={12} sm={12} lg={8} className="order-lg-1">
            <div className='form-content'>
              <h1>Contact <span className='blue'>Us</span></h1>
              <p className='pe-lg-5'>
                With K-W Property Management Corp, we make finding your new home easy!
                Contact us, and we will assist you in finding not only a home but the right home for you.
                Whether you are looking for an apartment, condo, single dwelling home, semi-detached, townhouse,
                executive suite, or even a bachelor apartment, we have plenty of selection to choose from.
                <br /> We will help you find a home that suits the needs and amenities that best fit your lifestyle.
                <br /> If you do not see your ideal home on our website, feel free to contact us and let us know what you are looking for.
                <h6 className='mt-2'>Our <span className=''>Mailing Address:</span> 541 Queen St S. Kitchener, ON N2G 1W9</h6>
                <h6>Phone <span className=''>Number:</span> 519-954-8082</h6>
                <h6>Email <span className=''>Address:</span> kwp@kwproperty.com</h6>
              </p>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={4} className="order-lg-2">
            <Card className='p-3 form-card mt-4 mx-auto' style={{ maxWidth: '500px' }}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Enter name"
                    maxLength={50}
                    className="form-control-sm"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Enter your email"
                    maxLength={100}
                    className="form-control-sm"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    type="text"
                    placeholder="Enter your phone number"
                    maxLength={15}
                    className="form-control-sm"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    as="textarea"
                    placeholder="Enter your message"
                    style={{ height: 'auto' }}
                    rows={4}
                    maxLength={500}
                    className="form-control-sm"
                  />
                </Form.Group>
                <div className="mt-2 mb-1" style={{ fontSize: "14px", wordSpacing: "1px" }}>
                  To protect this website from spam, please check the box below.
                </div>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={handleCaptcha}
                />
                <Button
                  onClick={isCaptchaVerified ? handleSubmit : () => toast.warning('Please verify you are not a robot.')}
                  className='btn btn-primary mt-2 w-100'
                >
                  Submit
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <KWFooter />
    </>
  );
};
