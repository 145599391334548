import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Container, Dropdown, DropdownButton, Form } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

import { LineWave } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import swal from 'sweetalert'
import KWAdminNavbar from '../../components/navbar/AdminNav'
import DOMPurify from 'dompurify';
import agents from '../../data/agents.json';

const UpdateEvent = () => {
    const navigate = useNavigate();
    const [isSubmitting, setSubmitting] = useState(false)
    const [property, setProperty] = useState([])
    const formRef = useRef(null)
    const [status, setStatus] = useState('')
    const location = useLocation()
    const [selectedAgent, setSelectedAgent] = useState(null)
    const [showingData, setShowingData] = useState({

        description: location.state?.description?.split(',')[1]?.split(':')[1]?.trim() || location.state?.description || '',

        summary: location.state?.summary.replace(/\[[a-zA-Z]+\]/, '') || '',
        start: {
            dateTime: location.state?.start.dateTime || '',
            timeZone: "America/Los_Angeles"
        },
        end: {
            dateTime: location.state?.end.dateTime || '',
            timeZone: "America/Los_Angeles"
        },
        extendedProperties: {
            shared: {
                propertyId: location.state?.extendedProperties?.shared.propertyId,
                agentName: location.state?.extendedProperties?.shared.agentName
            },
            private: {}
        },
    });

    useEffect(() => {
        setStatus(location.state.summary.match(/[\[a-z]+\]/) || '')
        const isLogin = sessionStorage.getItem('isLogin')
        if (isLogin !== 'true') {
            navigate('/admin')
        } else {
            fetchProperties()
            loadEventDetails()
        }
    }, [navigate])

    const fetchProperties = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1/listings/kwpweb/listing`)
            setProperty(data)
        } catch (err) {
            console.error(err)
        }
    }

    const loadEventDetails = () => {
        const eventDetails = location.state?.event
        if (eventDetails) {
            setShowingData({
                description: eventDetails.description || '',
                start: moment(eventDetails.start.dateTime).format('YYYY-MM-DDTHH:mm'),
                end: moment(eventDetails.end.dateTime).format('YYYY-MM-DDTHH:mm'),
                extendedProperties: {
                    shared: {
                        propertyId: eventDetails.extendedProperties?.shared.propertyId,
                        agentName: eventDetails.extendedProperties?.shared.agentName
                    },
                    private: {}
                },
                summary: eventDetails.summary.replace(/\[[a-zA-Z]+\]/, '') || ''
            })
            const agent = agents.find(agent => agent.name === eventDetails.extendedProperties?.shared.agentName);
            setSelectedAgent(agent);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
    
        if (name === "property") {
            setShowingData(prev => ({
                ...prev,
                extendedProperties: {
                    ...prev.extendedProperties,
                    shared: { ...prev.extendedProperties.shared, propertyId: value },
                },
                summary: `${event.target.selectedOptions[0].innerText}`,
            }));
        } else if (name === 'start') {
            // Directly update the start date without sanitization
            setShowingData(prev => ({
                ...prev,
                start: { ...prev.start, dateTime: new Date(value).toISOString() },
            }));
        } else if (name === 'end') {
            // Directly update the end date without sanitization
            setShowingData(prev => ({
                ...prev,
                end: { ...prev.end, dateTime: new Date(value).toISOString() },
            }));
        } else {
            // Sanitize other inputs as needed
            const sanitizedValue = DOMPurify.sanitize(value);
            setShowingData(prev => ({
                ...prev,
                [name]: sanitizedValue,
            }));
        }
    };
    

    const handleAgentSelect = (agentName) => {
        const sanitizedValue = DOMPurify.sanitize(agentName);
        setSelectedAgent(() => agents.find(agent => agent.name === sanitizedValue));
        setShowingData(prev => ({
            ...prev,
            extendedProperties: {
                ...prev.extendedProperties,
                shared: {
                    ...prev.extendedProperties.shared,
                    agentName: sanitizedValue,
                }
            }
        }));
    };
    useEffect(() => {
        setSelectedAgent(() => agents.filter(a => a.name === location?.state.extendedProperties?.shared.agentName)[0] || null)
    }, [])
 

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            const payload = {
                ...showingData,

                summary: `${status} ${showingData.summary.replace(/[\[a-zA-Z]+\]/, '').trim()}`,
                description: `${showingData.description}`,
                colorId: `${selectedAgent?.colorId}`,
            };
    
            await axios.patch(`${process.env.REACT_APP_API_URL}/v1/calender/event/${location.state?.id}`, payload);
    
            swal("Success", "Event updated successfully", "success").then(() =>
                navigate('/admin/event-list')
            );

        } catch (err) {
            console.error(err);
            swal("Error", "Failed to update event. Please try again.", "error");
        } finally {
            setSubmitting(false);
        }
    };
    

    return (
        <>
            <KWAdminNavbar />
            <Container className='pt-5'>
                <div className='form-content'>
                    <Col className='mx-auto' sm={12} lg={6}>
                        <Card className='pt-3 border-0 form-card mt-4'>
                            <h4 className='text-center'>Update Event</h4>
                            <Form ref={formRef}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Property</Form.Label>
                                    <Form.Select value={showingData.extendedProperties?.shared.propertyId} onChange={handleChange} name='property'>
                                        {property.length > 0 && property.map((p, index) => {
                                            return <option key={index} value={p.Unit.Id}>{p.Property.Name}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Agent Name <span className='text-danger'>*</span></Form.Label>
                                    <DropdownButton style={{ background: `${selectedAgent?.color_code}` }} className='form-control agent p-0' id="dropdown-basic-button" title={selectedAgent?.name || 'Select Agent'}>
                                        {agents.length > 0 ? (
                                            agents.map((A, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    style={{ background: selectedAgent?.name === A.name ? '#64626269' : '' }}
                                                    onClick={() => handleAgentSelect(A.name)}
                                                    className='d-flex justify-content-between align-items-center'>
                                                    {A.name}
                                                    <span style={{ color: `${A.color_code}`, fontSize: '30px' }}>●</span>
                                                </Dropdown.Item>
                                            ))
                                        ) : (
                                            <Dropdown.Item disabled>No Agent available</Dropdown.Item>
                                        )}
                                    </DropdownButton>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Start date</Form.Label>
                                    <Form.Control
                                        value={moment(showingData.start.dateTime).format("YYYY-MM-DDTHH:mm")}
                                        onChange={handleChange}
                                        name='start'
                                        type="datetime-local" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>End date</Form.Label>
                                    <Form.Control
                                        value={moment(showingData.end.dateTime).format("YYYY-MM-DDTHH:mm")}
                                        onChange={handleChange}
                                        name='end'
                                        type="datetime-local" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={showingData.description}
                                        onChange={handleChange} name='description' rows={3} placeholder="Description" />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select className={`text-${status == '[o]' ? 'success' : 'danger'}`} value={status} onChange={(e) => setStatus(e.target.value)} name="status">
                                        <option selected={status==="[o]"?true:false} className='text-success' value="[o]">Open</option>
                                        <option selected={status==="[c]"?true:false} className='text-danger' value="[c]">Closed</option>
                                    </Form.Select>
                                </Form.Group>
                                <Button onClick={handleSubmit} className='btn btn-primary mt-2' disabled={isSubmitting}>
                                    {isSubmitting ? (
                                        <LineWave
                                            height="20px"
                                            width="70px"
                                            color="#ffffff"
                                            ariaLabel="line-wave"
                                            visible={true}
                                        />
                                    ) : "Submit"}
                                </Button>
                            </Form>
                        </Card>
                    </Col>
                </div>
            </Container>
        </>
    )
}

export default UpdateEvent;
